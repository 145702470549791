import '@/plugins/composition-api'

import type { TemplateItemType } from "./editor"

export default function () {
  const icons: { [key in TemplateItemType | string]: string } = {
    Rect: 'ic:outline-square',
    Circle: 'ic:outline-circle',
    Text: 'ic:outline-text-snippet',
    Image: 'ic:outline-image',

    ParticipantImage: 'ic:outline-account-box',
    ParticipantQR: 'ic:outline-qr-code-2',
    ParticipantDegree: 'ic:round-school',
    ParticipantName: 'ic:round-drive-file-rename-outline',
    ParticipantFirstName: 'ic:round-drive-file-rename-outline',
    ParticipantLastName: 'ic:round-drive-file-rename-outline',
    ParticipantGroup: 'ic:round-group',
    ParticipantInstitution: 'ic:round-location-city',
    ParticipantLicenseNumber: 'ic:round-location-city',
    ParticipantPoints: 'ic:round-confirmation-number'
  }

  return { icons }
}
