
import '@/plugins/composition-api'

import Konva from 'konva';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { useVModel } from '@vueuse/core';

import type { TemplateItem } from '@/use/templates/editor'
import useEditorShapes from '@/use/templates/editorShapes'

export default defineComponent({
  components: { Draggable: () => import('vuedraggable') },
  props: {
    value: {
      type: Array as PropType<TemplateItem[]>,
      required: false,
      default: () => []
    },
    shapes: {
      type: Array as PropType<Konva.Shape[]>,
      required: false,
      default: () => []
    },
    selectedShapes: {
      type: Array as PropType<Konva.Shape[]>,
      required: false,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const model = useVModel(props, 'value', emit, { eventName: 'input' })
    const shapesModel = useVModel(props, 'shapes', emit)
    const selectedShapesModel = useVModel(props, 'selectedShapes', emit)

    const list = computed({
      get: () => model.value.slice().reverse(),
      set: (value) => model.value = value.slice().reverse()
    })

    const { icons } = useEditorShapes()

    const onItemClick = (item: TemplateItem, event: MouseEvent) => {
      const shape = shapesModel.value.find(shape => shape.id() === item.config.id)

      if (shape) {
        if (event.ctrlKey || event.metaKey || event.shiftKey) {
          if (selectedShapesModel.value.find(item => item.id() === shape.id())) {
            selectedShapesModel.value = selectedShapesModel.value.filter(item => item.id() !== shape.id())
          } else {
            selectedShapesModel.value = [...selectedShapesModel.value, shape]
          }
        } else {
          selectedShapesModel.value = [shape]
        }
      }
    }

    const drag = ref(false)

    const dragOptions = computed(() => ({
      itemKey: (item: TemplateItem) => item.config.id,
      animation: 200,
      group: "template-items",
      disabled: false,
      ghostClass: "ghost",
      handle: '.handle',
      forceFallback: true,
      tag: 'div',
      class: 'template-editor-shapes__wrapper',
    }))

    return { list, icons, onItemClick, drag, dragOptions, shapesModel, selectedShapesModel }
  }
})
